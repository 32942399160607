




































































































































































import { HelpingMessage } from "@/components/atom/helping";
import { ROUTE_NAMES } from "@/router/routes";
import { rentalsService } from "@/services";
import { extension } from "mime-types";
import {
  DocumentFileType,
  DocumentModelType,
  getMoment,
  modelTypeMapping,
} from "@edmp/api";
import { defineComponent, onMounted, onUnmounted } from "@vue/composition-api";
import PageHeader from "../../atom/PageHeader.vue";
import { dispatchModelsLibraryEvent, ModelsLibraryEventCode } from "@/events";
import { usersStore, productsStore, subscriptionsStore } from "@/store";

export default defineComponent({
  name: "ModelsLibrary",
  components: {
    HelpingMessage,
    PageHeader,
  },
  setup() {
    const headers = [
      { text: "Libellé", value: "name" },
      { text: "options", value: "options" },
      { text: "Format de téléchargement", value: "format" },
    ];
    const headersWithoutOptions = [
      { text: "Libellé", value: "name" },
      { text: "Format de téléchargement", value: "format" },
    ];
    const leaseAmendmentList: {
      text: string;
      value: DocumentModelType;
    }[] = [
      {
        text: "Arrivée d'un nouveau locataire",
        value: DocumentModelType.LEASE_AMENDMENT_ARRIVAL_OF_NEW_TENANT,
      },
      {
        text: "Arrivée d'un nouveau locataire avec clause de solidarité",
        value:
          DocumentModelType.LEASE_AMENDMENT_ARRIVAL_OF_NEW_TENANT_WITH_SOLIDARITY_CLAUSE,
      },
      {
        text: "Changement de date de paiement",
        value: DocumentModelType.LEASE_AMENDMENT_PAYMENT_DATE_CHANGE,
      },
      {
        text: "Changement de locataire",
        value: DocumentModelType.LEASE_AMENDMENT_CHANGE_OF_TENANT,
      },
      {
        text: "Départ d'un locataire",
        value: DocumentModelType.LEASE_AMENDMENT_DEPARTURE_OF_A_TENANT,
      },
      {
        text: "Franchise temporaire de loyer",
        value: DocumentModelType.LEASE_AMENDMENT_TEMPORARY_RENT_FREE_PERIOD,
      },
      {
        text: "Prolongation d'un bail mobilité",
        value: DocumentModelType.LEASE_AMENDMENT_EXTENSION_OF_A_MOBILITY_LEASE,
      },
    ];
    const sections: {
      name: string;

      models: {
        name: string;
        modelType?: DocumentModelType;
        hasOptions?: boolean;
        list?: object;
        withVat?: boolean;
        withApl?: boolean;
        lessor?: string;
        tenant?: string;
        type?: string;
        indexType?: string;
        hasText?: boolean;
      }[];
    }[] = [
      {
        name: "Modèles de gestion locative",
        models: [
          {
            name: "Quittance de loyer",
            modelType: DocumentModelType.RENT_RECEIPT,
            hasOptions: true,
            withVat: false,
            withApl: false,
          },
          {
            name: "Appel de loyer",
            modelType: DocumentModelType.RENT_CALL,
            hasOptions: true,
            withVat: false,
            withApl: false,
          },
          {
            name: "Relance de loyer impayé",
            modelType: DocumentModelType.REMINDER_OF_UNPAID_RENT,
          },
          {
            name: "Relance attestation d'assurance habitation",
            modelType: DocumentModelType.REMINDER_INSURANCE_CERTIFICATE,
          },
          {
            name: "Reçu de loyer",
            modelType: DocumentModelType.PARTIAL_RENT_RECEIPT,
          },
          {
            name: "État des lieux",
            modelType: DocumentModelType.STATE_OF_PLAY,
            type: "studio",
          },
          {
            name: "Inventaire",
            modelType: DocumentModelType.INVENTORY,
            hasText: true,
          },
        ],
      },
      {
        name: "Préavis d'indexation et de régularisation",
        models: [
          {
            name: "Préavis de régularisation des charges - En faveur du bailleur",
            modelType: DocumentModelType.NOTICE_ADJUSTMENT_OF_CHARGES_LESSOR,
          },
          {
            name: "Préavis de régularisation des charges - En faveur du locataire",
            modelType: DocumentModelType.NOTICE_ADJUSTMENT_OF_CHARGES_TENANT,
          },
          {
            name: "Préavis de révision du loyer",
            modelType: DocumentModelType.NOTICE_RENT_REVIEW,
            indexType: "irl",
          },
        ],
      },
      {
        name: "Procédures de recouvrement",
        models: [
          {
            name: "Mise en demeure AR",
            modelType: DocumentModelType.FORMAL_NOTICE,
          },
        ],
      },
      {
        name: "Documents de garantie locative",
        models: [
          {
            name: "Acte de cautionnement solidaire à durée déterminée",
            modelType:
              DocumentModelType.DEED_OF_JOINT_AND_SEVERAL_SURETY_FOR_A_FIXED_TERM,
          },
          {
            name: "Acte de cautionnement solidaire à durée indéterminée",
            modelType:
              DocumentModelType.DEED_OF_JOINT_AND_SEVERAL_SURETY_FOR_AN_INDEFINITE_TERM,
          },
          {
            name: "Acte de cautionnement simple à durée déterminée",
            modelType: DocumentModelType.DEED_OF_SIMPLE_SURETY_FOR_A_FIXED_TERM,
          },
          {
            name: "Acte de cautionnement simple à durée indéterminée",
            modelType:
              DocumentModelType.DEED_OF_SIMPLE_SURETY_FOR_AN_INDEFINITE_TERM,
          },
        ],
      },
      {
        name: "Modèles de contrats de location",
        models: [
          {
            name: "Bail location nue",
            modelType: DocumentModelType.UNFURNISHED_LEASE,
          },
          {
            name: "Bail location meublé",
            modelType: DocumentModelType.FURNISHED_LEASE,
          },
          {
            name: "Bail mobilité",
            modelType: DocumentModelType.MOBILITY_LEASE,
          },
          {
            name: "Avenant au bail",
            modelType: DocumentModelType.LEASE_AMENDMENT_ARRIVAL_OF_NEW_TENANT,
            list: leaseAmendmentList,
            lessor: "company",
            tenant: "nat_pers",
          },
          {
            name: "Attestation de fin de bail",
            modelType: DocumentModelType.END_OF_LEASE_CERTIFICATE,
          },
        ],
      },
    ];

    const getModelType = (item): DocumentModelType => {
      const key = `${item.modelType}${
        item.withApl && !item.withVat
          ? " avec APL"
          : !item.withApl && item.withVat
          ? " avec TVA"
          : item.withApl && item.withVat
          ? " avec TVA et APL"
          : ""
      }${
        item.lessor === "nat_pers" && item.tenant === "nat_pers"
          ? " - Bailleur Particulier - Locataire Particulier"
          : item.lessor === "company" && item.tenant === "nat_pers"
          ? " - Bailleur Société - Locataire Particulier"
          : item.lessor === "nat_pers" && item.tenant === "company"
          ? " - Bailleur Particulier - Locataire Société"
          : item.lessor === "company" && item.tenant === "company"
          ? " - Bailleur Société - Locataire Société"
          : ""
      }${
        item.type === "studio"
          ? " - Studio - T1"
          : item.type === "t2"
          ? " - T2 - T3"
          : item.type === "t4"
          ? " - T4 - T5"
          : item.type === "house"
          ? " - Maison"
          : item.type === "commercial"
          ? " - Commercial"
          : ""
      }${
        item.indexType === "irl"
          ? " - IRL"
          : item.indexType === "ilc"
          ? " - ILC"
          : item.indexType === "icc"
          ? " - ICC"
          : item.indexType === "ilat"
          ? " - ILAT"
          : ""
      }`;

      const modelType = modelTypeMapping[key];

      if (!modelType) {
        throw new Error(`Model type not found for key: ${key}`);
      }

      return modelType;
    };

    const downloadModel = async (item, fileType: DocumentFileType) => {
      const modelType = getModelType(item);

      await rentalsService.agreements.downloadDocumentModel({
        modelType,
        fileType,
      });

      dispatchModelsLibraryEvent({
        userId: usersStore.loggedInUser.id,
        productId: productsStore.currentId,
        subscriptionPlan:
          subscriptionsStore.getCurrentSubscription?.plan.type || "unknown",
        date: getMoment().toISOString(),
        code:
          fileType === "docx"
            ? ModelsLibraryEventCode.CLICK_DOWNLOAD_MODEL_DOCX
            : ModelsLibraryEventCode.CLICK_DOWNLOAD_MODEL_PDF,
        modelType: String(modelType),
      });
    };

    onMounted(() => {
      dispatchModelsLibraryEvent({
        userId: usersStore.loggedInUser.id,
        productId: productsStore.currentId,
        subscriptionPlan:
          subscriptionsStore.getCurrentSubscription?.plan.type || "unknown",
        date: getMoment().toISOString(),
        code: ModelsLibraryEventCode.ENTER_MODELS_LIBRARY,
      });
    });
    onUnmounted(() => {
      dispatchModelsLibraryEvent({
        userId: usersStore.loggedInUser.id,
        productId: productsStore.currentId,
        subscriptionPlan:
          subscriptionsStore.getCurrentSubscription?.plan.type || "unknown",
        date: getMoment().toISOString(),
        code: ModelsLibraryEventCode.QUIT_MODELS_LIBRARY,
      });
    });
    return {
      headers,
      headersWithoutOptions,
      ROUTE_NAMES,
      sections,
      downloadModel,
      extension,
      DocumentFileType,
    };
  },
});
