var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"accrual-card pa-4 pa-md-6 mb-5"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"mr-6"},[_c('v-avatar',{staticClass:"accrual-card-icon",attrs:{"color":_vm.computedAvatar,"size":"22"}},[_c('v-icon',{attrs:{"color":"#fff","small":""}},[_vm._v(" "+_vm._s(_vm.amount >= 0 ? "mdi-plus" : "mdi-minus")+" ")])],1)],1):_vm._e(),_c('div',{staticClass:"accrual-card-details mr-sm-1"},[_c('div',[_c('div',{staticClass:"accrual-card-label"},[_c('p',{staticClass:"accrual-card-summary mb-0 mr-10"},[_vm._v(" "+_vm._s(_vm.operationAccrual.journalEntry.description)+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(
                _vm.removable &&
                !_vm.operationAccrual.reconciliation &&
                !_vm.operationAccrual.reportedLoss
              )?_c('v-btn',_vm._g(_vm._b({staticClass:"mx-md-2",attrs:{"color":"#000","icon":"","small":""},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.$emit('remove')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v(" mdi-delete-outline")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Supprimer cet engagement")])])],1),_c('div',{staticClass:"accrual-card-amount"},[_vm._v(" "+_vm._s(_vm.$n(_vm.amount, "currency", "fr-FR"))+" ")]),_c('div',[(_vm.categories.length)?_c('div',{staticClass:"accrual-card-categories my-2 d-flex"},_vm._l((_vm.categories),function(category){return _c('div',{key:((category.id) + "-" + (category.accountName)),staticClass:"accrual-card-category"},[_c('span',[_c('v-expansion-panels',{attrs:{"flat":true}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"id":"test_expansion_header_accrual"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{staticClass:"icon"},[_vm._v("$expand")])]},proxy:true}],null,true)},[_c('span',{staticClass:"header mt-1"},[_c('strong',{staticClass:"accrual-card-category",attrs:{"id":"accrual-category-name"}},[_vm._v(" "+_vm._s(category.accountName)+" ")])])]),_c('v-expansion-panel-content',[(category.realEstateAsset)?_c('v-chip',{staticClass:"chip",attrs:{"small":"","outlined":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-home-outline ")]),(category.realEstateAsset)?_c('span',_vm._g(_vm._b({staticClass:"textOverflow"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getAttribute( "realEstateAsset", category.realEstateAsset ))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.getAttribute( "realEstateAsset", category.realEstateAsset )))])])],1):_vm._e(),(category.fixedAsset)?_c('v-chip',{staticClass:"chip",attrs:{"small":"","outlined":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-home-clock-outline ")]),(category.fixedAsset)?_c('span',_vm._g(_vm._b({staticClass:"textOverflow"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getAttribute("fixedAsset", category.fixedAsset))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.getAttribute("fixedAsset", category.fixedAsset)))])])],1):_vm._e(),(category.rentalAgreement)?_c('v-chip',{staticClass:"chip",attrs:{"small":"","outlined":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-key-outline ")]),(category.rentalAgreement)?_c('span',_vm._g(_vm._b({staticClass:"textOverflow"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getAttribute( "rentalAgreement", category.rentalAgreement ))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.getAttribute( "rentalAgreement", category.rentalAgreement ))+" ")])])],1):_vm._e(),(category.tenant)?_c('v-chip',{staticClass:"chip",attrs:{"small":"","outlined":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-key-outline ")]),(category.tenant)?_c('span',_vm._g(_vm._b({staticClass:"textOverflow"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getAttribute("tenant", category.tenant))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.getAttribute("tenant", category.tenant))+" ")])])],1):_vm._e(),(category.partner)?_c('v-chip',{staticClass:"chip",attrs:{"small":"","outlined":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-outline ")]),(category.partner)?_c('span',_vm._g(_vm._b({staticClass:"textOverflow"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getAttribute("partner", category.partner))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.getAttribute("partner", category.partner))+" ")])])],1):_vm._e(),(category.realEstateLoan)?_c('v-chip',{staticClass:"chip",attrs:{"small":"","outlined":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-home-percent-outline ")]),(category.realEstateLoan)?_c('span',_vm._g(_vm._b({staticClass:"textOverflow"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getAttribute( "realEstateLoan", category.realEstateLoan ))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getAttribute( "realEstateLoan", category.realEstateLoan )))])])],1):_vm._e(),(category.supportingDocument)?_c('v-chip',{staticClass:"chip",attrs:{"small":"","outlined":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [(category.supportingDocument)?_c('a',{staticClass:"supporting-document",attrs:{"href":_vm.getSupportingDocument(
                                category.supportingDocument
                              ),"target":"_blank"}},[_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-folder-outline ")]),(category.supportingDocument)?_c('span',_vm._g(_vm._b({staticClass:"textOverflow"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getAttribute( "supportingDocument", category.supportingDocument ))+" ")]):_vm._e()],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.getAttribute( "supportingDocument", category.supportingDocument ))+" ")])])],1):_vm._e(),(category.beneficiary)?_c('v-chip',{staticClass:"chip",attrs:{"small":"","outlined":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
                              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-hand-extended ")]),(category.beneficiary)?_c('span',_vm._g(_vm._b({staticClass:"textOverflow"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getAttribute( "beneficiary", category.beneficiary ))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getAttribute("beneficiary", category.beneficiary)))])])],1):_vm._e()],1)],1)],1)],1),_c('span',{staticClass:"accrual-card-category-amount ml-3 ml-sm-5 ml-md-10"},[_vm._v(" "+_vm._s(_vm.$n(category.amount, "currency", "fr-FR"))+" ")])])}),0):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }