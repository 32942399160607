import { AccountingPeriodDefinition, UserAccount, SpecProvider, TaxRegime, TaskCode } from "../";
import { Transporter } from "nodemailer";
import SMTPTransport from "nodemailer/lib/smtp-transport";
import stripe from "stripe";
import { CookieSerializeOptions } from "cookie";
import { TosVersion } from "./User.model";
import { Dictionary } from "lodash";

//Tool
export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

// Gateway
export interface ConfigGatewayDns {
  url: string;
  trustProxy: boolean;
}

export interface ConfigGatewayRateLimit {
  limit: number;
}

export interface ConfigGatewayToken {
  audience: string;
  cookieName: string | null;
  expiresIn: string;
  maxAge: string;
  issuer: string;
  secret: string;
  // cookieName: string;
}

export interface ConfigGatewayHelmet {
  contentSecurityPolicy: {
    useDefaults?: boolean;
    directives?: Record<string, null | Iterable<string>>;
    reportOnly?: boolean;
  };
}

// Services
export interface ConfigServiceS3 {
  host: string;
  bucket: string;
  key_id: string;
  key_secret: string;
  location: string;
  signedUrlExpires: number;
}

export interface ConfigServiceElactic {
  apm: {
    active: boolean;
    serviceName: string;
    secretToken: string;
    serverUrl: string;
    consoleUrl: string;
  };
  log: {
    active: boolean;
    elasticUrl: string;
    logName: string;
  };
}

export interface ConfigServiceMailer {
  SMTP?: SMTPTransport.Options & { pool: boolean };
  defaults?: SMTPTransport.Options;
  transport?: Transporter<SMTPTransport.SentMessageInfo>;
  precompiledMailTemplates?: Dictionary<string>;
}

export interface ConfigServiceCrisp {
  identifier: string;
  key: string;
  websiteId: string;
}

export interface ConfigServiceHubspot {
  apiV3: string;
  apiV4: string;
  token: string;
  isDisableFlux?: boolean;
  migration?: {
    isEnable: boolean;
    userIds: string[];
    allExceptUserIds?: boolean;
  };
}

export interface ConfigServiceStripe {
  publicKey: string;
  secretKey: string;
  webhookKey: string;
  apiVersion: stripe.LatestApiVersion;
  env: string;
}

export interface ConfigServiceTeledec {
  clientId: string;
  clientSecret: string;
  authUrl: string;
  apiUrl: string;
  callbackPath: string;
  scopeEnv: "stage" | "prod";
}

export interface ConfigServiceInsee {
  api: {
    key: string;
    secret: string;
  };
}

export interface ConfigServiceGoogleSheet {
  docId: string;
  credentials: {
    type: string;
    project_id: string;
    private_key_id: string;
    private_key: string;
    client_email: string;
    client_id: string;
    auth_uri: string;
    token_uri: string;
    auth_provider_x509_cert_url: string;
    client_x509_cert_url: string;
  };
}

// App
export interface ConfigAppDefault {
  companyName: string;
  realEstateAssetName: string;
  rentalUnitName: string;
}

export interface ConfigAppUser {
  resetPasswordExpiration: number;
  emailVerifyExpiration: number;
  tosVersion: TosVersion;
}

export interface ConfigAppIdp {
  bcrypt: {
    iterations: number;
  };
}

// App Transaction
export interface ConfigAppTransactionProviderTransaction {
  id: number;
  date: string;
  value: number;
  original_wording: string;
  type: string;
  deleted: boolean;
}

export interface ConfigAppTransaction {
  landing: {
    bi: {
      redirect: string;
    };
    mock: {
      redirect: string;
    };
    mock3mois: {
      redirect: string;
    };
  };
  banks: {
    BI: SpecProvider;
    mock: SpecProvider;
    mock3mois: SpecProvider;
    mockNoTransactions: SpecProvider;
  };
  bi: {
    url: string;
    webviewUrl: string;
    logosUrl: string;
    domain: string;
    client_id: string;
    client_secret: string;
    landing: string;
    config_token: string;
    supported_account_types: string[];
    registrationExpiry: string;
    supported_currencies: string[];
  };
  mock: {
    url: string;
    transactions: ConfigAppTransactionProviderTransaction[];
  };
  mock3mois: {
    url: string;
    transactions: ConfigAppTransactionProviderTransaction[];
  };
  mockNoTransactions: {
    url: string;
    transactions: ConfigAppTransactionProviderTransaction[];
  };
  [key: string]: unknown;
}

export type ConfigAppAccounting = {
  [key in TaxRegime]?: {
    isClosureFlowBlocked?: boolean;
    periods: { [key: string | number]: AccountingPeriodDefinition };
  };
};

export type ConfigAppFeatureFlags = DeepPartial<{
  // Activate feature by userId
  tasks: {
    code: {
      [TaskCode.TeletransmitMyTaxReturn]: string[];
    };
  };
}>;

export interface ConfigAppSubscription {
  freeOfferDurationDays: number;
  additionalDayForIS: number;
  daysBeforeBlockAccount: number;
  retentionDatasDaysDefault: number;
}

export interface ConfigAppVersions {
  back: string;
  front: string;
  api: string;
}

// ConfigSettings
export interface ConfigSettings {
  logInternalEvents: boolean;
  environment: string;
  maintenance: {
    isEnable: boolean;
    redirectUrl: string;
    startAt?: string;
  };
  gateway: {
    origin: string[];
    port: number;
    dns: ConfigGatewayDns;
    rateLimit: ConfigGatewayRateLimit;
    commit_sha: string;
    cookie: CookieSerializeOptions;
    token: ConfigGatewayToken;
    helmet: ConfigGatewayHelmet;
  };
  services: {
    mongo: string;
    s3: ConfigServiceS3;
    elastic: ConfigServiceElactic;
    mailer: ConfigServiceMailer;
    crm: string;
    crisp: ConfigServiceCrisp;
    hubspot: ConfigServiceHubspot;
    stripe: ConfigServiceStripe;
    teledec: ConfigServiceTeledec;
    insee: ConfigServiceInsee;
    webSocket: boolean;
  };
  app: {
    identifiant: string;
    default: ConfigAppDefault;
    user: ConfigAppUser;
    idp: ConfigAppIdp;
    transaction: ConfigAppTransaction;
    accounting: ConfigAppAccounting;
    subscription: ConfigAppSubscription;
    featureFlags: ConfigAppFeatureFlags;
    adminUsers: UserAccount[];
    versions: ConfigAppVersions;
  };
}
