import { ROUTE_NAMES } from "@/router/routes";
import {
  accountingBalanceSheetsStore,
  accountingPeriodsStore,
  realEstateAssetsStore,
  subscriptionsStore,
  tasksStore,
} from "@/store";
import {
  SubscriptionsModel,
  TaskStatus,
  TaskGroupCode,
  TaskCode,
  TaxRegime,
  RentalUsage,
} from "@edmp/api";
import { ComputedRef, computed } from "@vue/composition-api";

export type NAVIGATION_MENU =
  | "TaxDeclaration"
  | "Events"
  | "Accounting"
  | "StartWell"
  | keyof Pick<
      typeof ROUTE_NAMES,
      | "Dashboard"
      | "Transactions"
      | "RentalAgreements"
      | "RentalBuildings"
      | "SciIncome"
      | "TaxDeclaration2065"
      | "TaxDeclaration2033"
      | "TaxDeclaration2031"
      | "PartnersIncome"
      | "AccountingDashboard"
      | "AccountingDocuments"
      | "AccountingOperations"
      | "AccountingBalanceSheet"
      | "AccountingBalanceSheetRecovery"
      | "Amortisations"
      | "EventsGeneralAssembly"
      | "EventsYearEnd"
      | "Documents"
      | "SupportingDocuments"
      | "FixedAssets"
      | "Tasks"
      | "EventsYearEnd"
      | "MyBusiness"
      | "RealEstateAssets"
      | "PartnersArea"
      | "Accruals"
    >;

type NavigationItem = {
  name: string;
  routeName?: string;
  new?: boolean;
  icon?: string;
  isButton?: boolean;
};
type NavigationItemChildren = Omit<NavigationItem, "icon" | "isButton"> & {
  icon?: string;
};
export type NavigationItems = (NavigationItem & {
  children?: NavigationItemChildren[];
})[];

// Secure the navigation titles with the route names
// And inject parents to aggregate some of them

export const useNavigationItems = () => {
  const taxRegime = computed(
    () =>
      accountingPeriodsStore.currentAccountingPeriod?.taxRegime ??
      TaxRegime.IR_2072
  );
  const subscriptionType = computed(
    () => subscriptionsStore.getCurrentSubscription?.plan.type
  );

  const isCompletedStartWellTask = computed(() =>
    tasksStore.startWellTaskGroup.every(
      (taskGroup) => taskGroup.status === TaskStatus.COMPLETED
    )
  );
  const isCompletedStartWellAccountingBalanceSheetRecoveryTask = computed(() =>
    taxRegime.value === TaxRegime.IS_2065 ||
    taxRegime.value === TaxRegime.LMNP_2031
      ? !!tasksStore.startWellTaskGroup
          .find(
            (taskGroup) =>
              taskGroup.groupCode === TaskGroupCode.StartWellAccounting
          )
          ?.tasksLocal.find(
            (task) =>
              (task.code === TaskCode.BalanceSheetRecovery &&
                task.status === TaskStatus.COMPLETED) ||
              (task.code === TaskCode.InjectBalance &&
                task.status === TaskStatus.COMPLETED)
          )
      : taxRegime.value === TaxRegime.IR_2072
      ? !!tasksStore.startWellTaskGroup
          .find((taskGroup) => taskGroup.groupCode === TaskGroupCode.StartWell)
          ?.tasksLocal.find(
            (task) =>
              (task.code === TaskCode.BalanceSheetRecovery ||
                task.code === TaskCode.InjectBalance) &&
              task.status === TaskStatus.COMPLETED
          )
      : true
  );
  const hasRealEstateAssetRentalUsageLongTerm = computed(() =>
    realEstateAssetsStore.rentalUnits.some(
      (rentalUnit) => rentalUnit.rentalUsage === RentalUsage.LONG_TERM_RENTAL
    )
  );

  const NAVIGATION_TITLES: ComputedRef<{
    [key in NAVIGATION_MENU]: string;
  }> = computed(() => ({
    StartWell: "Bien démarrer sur Ownily",
    Tasks:
      accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
      TaxRegime.LMNP_2031
        ? "Paramétrage de mon activité"
        : "Paramétrage de ma SCI",
    Dashboard: "Mon tableau de bord",
    Transactions: "Mes transactions",
    RentalAgreements: "Suivi des loyers",
    RentalBuildings: "Mes immeubles",
    SciIncome: "Résultat de la SCI",
    TaxDeclaration2065: "Résultat de la SCI (2065)",
    TaxDeclaration2033: "Déclaration 2033",
    TaxDeclaration2031: "Déclaration 2031",
    PartnersIncome: "Revenus des associés",
    TaxDeclaration: "Mes déclarations fiscales",
    TVADeclaration: "Déclaration de la TVA",
    Accounting: "Ma comptabilité",
    AccountingDashboard: "Ma trésorerie",
    AccountingDocuments: "Mes documents comptables",
    AccountingOperations: "Opérations comptables",
    AccountingBalanceSheet: "Mon bilan",
    AccountingBalanceSheetRecovery: "Reprise de ma comptabilité",
    Amortisations: "Mes amortissements",
    Events: "Mes événements",
    EventsGeneralAssembly: "Assemblée Générale",
    EventsYearEnd:
      accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
      TaxRegime.LMNP_2031
        ? "Déclarer mon LMNP"
        : "Clôture de l'exercice",
    Documents: "Mon espace documentaire",
    SupportingDocuments: "Mes justificatifs",
    FixedAssets: "Mes immobilisations",
    MyBusiness: "Mon activité",
    RealEstateAssets: "Mes biens",
    PartnersArea: "Offres partenaires",
    Accruals: "Mes engagements",
  }));

  const navigationItemWellStart: ComputedRef<NavigationItems> = computed(() => {
    if (
      !isCompletedStartWellTask.value ||
      !isCompletedStartWellAccountingBalanceSheetRecoveryTask.value
    ) {
      return [
        {
          name: NAVIGATION_TITLES.value.StartWell,
          icon: "mdi-apple-safari",
          children: [
            {
              name: NAVIGATION_TITLES.value.Tasks,
              routeName: ROUTE_NAMES.Tasks,
            },
            (taxRegime.value !== TaxRegime.IR_2072 ||
              (taxRegime.value === TaxRegime.IR_2072 &&
                subscriptionType.value ===
                  SubscriptionsModel.PlanType.Premium)) &&
            accountingBalanceSheetsStore.hasPreviousYearBalanceSheetRecovery &&
            !accountingBalanceSheetsStore.isSkippedAccountingBalanceSheetRecovery &&
            !isCompletedStartWellAccountingBalanceSheetRecoveryTask.value
              ? {
                  name: NAVIGATION_TITLES.value.AccountingBalanceSheetRecovery,
                  routeName: ROUTE_NAMES.AccountingBalanceSheetRecovery,
                }
              : undefined,
          ],
        },
      ] as NavigationItems;
    } else return [];
  });

  const navigationItemsIR: ComputedRef<NavigationItems> = computed(() => {
    const navigationItems = navigationItemWellStart.value;
    const items = [
      {
        name: NAVIGATION_TITLES.value.Dashboard,
        icon: "mdi-home-outline",
        routeName: ROUTE_NAMES.Dashboard,
      },
      {
        name: NAVIGATION_TITLES.value.Transactions,
        icon: "mdi-swap-horizontal",
        routeName: ROUTE_NAMES.Transactions,
      },
      {
        name: NAVIGATION_TITLES.value.RentalAgreements,
        icon: "mdi-account-key-outline theme--light",
        routeName: ROUTE_NAMES.RentalAgreements,
      },
      {
        name: NAVIGATION_TITLES.value.TaxDeclaration,
        icon: "mdi-file-document-outline",
        children: [
          {
            name: NAVIGATION_TITLES.value.RentalBuildings,
            routeName: ROUTE_NAMES.RentalBuildings,
          },
          {
            name: NAVIGATION_TITLES.value.SciIncome,
            routeName: ROUTE_NAMES.SciIncome,
          },
          {
            name: NAVIGATION_TITLES.value.PartnersIncome,
            routeName: ROUTE_NAMES.PartnersIncome,
          },
        ],
      },
      {
        name: NAVIGATION_TITLES.value.Accounting,
        icon: "mdi-book-open-outline",
        children: [
          {
            name: NAVIGATION_TITLES.value.AccountingDashboard,
            routeName: ROUTE_NAMES.AccountingDashboard,
          },
          {
            name: NAVIGATION_TITLES.value.AccountingDocuments,
            routeName: ROUTE_NAMES.AccountingDocuments,
          },
        ],
      },
      {
        name: NAVIGATION_TITLES.value.Events,
        icon: "mdi-calendar-outline",
        children: [
          {
            name: NAVIGATION_TITLES.value.EventsGeneralAssembly,
            routeName: ROUTE_NAMES.EventsGeneralAssembly,
          },
          {
            name: NAVIGATION_TITLES.value.EventsYearEnd,
            routeName: ROUTE_NAMES.EventsYearEnd,
          },
        ],
      },
      {
        name: NAVIGATION_TITLES.value.Documents,
        icon: "mdi-folder-outline",
        routeName: ROUTE_NAMES.Documents,
      },
      {
        name: NAVIGATION_TITLES.value.PartnersArea,
        icon: "mdi-handshake-outline",
        routeName: ROUTE_NAMES.PartnersArea,
        new: true,
      },
    ];

    return navigationItems.concat(items);
  });

  const navigationItemsIS: ComputedRef<NavigationItem[]> = computed(() => {
    const navigationItems = navigationItemWellStart.value;

    const items = [
      {
        name: NAVIGATION_TITLES.value.Dashboard,
        icon: "mdi-home-outline",
        routeName: ROUTE_NAMES.Dashboard,
      },
      {
        name: NAVIGATION_TITLES.value.Transactions,
        icon: "mdi-swap-horizontal",
        routeName: ROUTE_NAMES.Transactions,
      },
      {
        name: NAVIGATION_TITLES.value.RentalAgreements,
        icon: "mdi-account-key-outline theme--light",
        routeName: ROUTE_NAMES.RentalAgreements,
      },
      {
        name: NAVIGATION_TITLES.value.TaxDeclaration,
        icon: "mdi-file-document-outline",
        children: [
          {
            name: NAVIGATION_TITLES.value.TaxDeclaration2065,
            routeName: ROUTE_NAMES.TaxDeclaration2065,
          },
          {
            name: NAVIGATION_TITLES.value.TaxDeclaration2033,
            routeName: ROUTE_NAMES.TaxDeclaration2033,
          },
        ],
      },
      {
        name: NAVIGATION_TITLES.value.Accounting,
        icon: "mdi-book-open-outline",
        children: [
          {
            name: NAVIGATION_TITLES.value.AccountingDashboard,
            routeName: ROUTE_NAMES.AccountingDashboard,
          },
          {
            name: NAVIGATION_TITLES.value.AccountingDocuments,
            routeName: ROUTE_NAMES.AccountingDocuments,
          },
          {
            name: NAVIGATION_TITLES.value.SupportingDocuments,
            routeName: ROUTE_NAMES.SupportingDocuments,
          },
          {
            name: NAVIGATION_TITLES.value.Amortisations,
            routeName: ROUTE_NAMES.Amortisations,
          },
          {
            name: NAVIGATION_TITLES.value.FixedAssets,
            routeName: ROUTE_NAMES.FixedAssets,
          },
          {
            name: NAVIGATION_TITLES.value.Accruals,
            routeName: ROUTE_NAMES.Accruals,
          },
        ],
      },
      {
        name: NAVIGATION_TITLES.value.Events,
        icon: "mdi-calendar-outline",
        children: [
          {
            name: NAVIGATION_TITLES.value.EventsGeneralAssembly,
            routeName: ROUTE_NAMES.EventsGeneralAssembly,
          },
          {
            name: NAVIGATION_TITLES.value.EventsYearEnd,
            routeName: ROUTE_NAMES.EventsYearEnd,
          },
        ],
      },
      {
        name: NAVIGATION_TITLES.value.Documents,
        icon: "mdi-folder-outline",
        routeName: ROUTE_NAMES.Documents,
      },
      {
        name: NAVIGATION_TITLES.value.PartnersArea,
        icon: "mdi-handshake-outline",
        routeName: ROUTE_NAMES.PartnersArea,
        new: true,
      },
    ];

    return navigationItems.concat(items);
  });

  const navigationItemsLMNP: ComputedRef<
    (NavigationItems[number] | undefined)[]
  > = computed(() => {
    const navigationItems = [
      {
        name: NAVIGATION_TITLES.value.MyBusiness,
        icon: "mdi-home-account",
        routeName: ROUTE_NAMES.MyBusiness,
      },
      {
        name: NAVIGATION_TITLES.value.RealEstateAssets,
        icon: "mdi-home-city-outline",
        routeName: ROUTE_NAMES.RealEstateAssets,
      },
      (subscriptionType.value === SubscriptionsModel.PlanType.Free &&
        hasRealEstateAssetRentalUsageLongTerm.value) ||
      subscriptionType.value === SubscriptionsModel.PlanType.LMNPOptimum
        ? {
            name: NAVIGATION_TITLES.value.RentalAgreements,
            icon: "mdi-account-key-outline theme--light",
            routeName: ROUTE_NAMES.RentalAgreements,
          }
        : undefined,
      {
        name: NAVIGATION_TITLES.value.Transactions,
        icon: "mdi-swap-horizontal",
        routeName: ROUTE_NAMES.Transactions,
      },
      {
        name: NAVIGATION_TITLES.value.Accounting,
        icon: "mdi-book-open-outline",
        children: [
          {
            name: NAVIGATION_TITLES.value.AccountingDocuments,
            routeName: ROUTE_NAMES.AccountingDocuments,
          },
          {
            name: NAVIGATION_TITLES.value.SupportingDocuments,
            routeName: ROUTE_NAMES.SupportingDocuments,
          },
          {
            name: NAVIGATION_TITLES.value.Amortisations,
            routeName: ROUTE_NAMES.Amortisations,
          },
          {
            name: NAVIGATION_TITLES.value.FixedAssets,
            routeName: ROUTE_NAMES.FixedAssets,
          },
          {
            name: NAVIGATION_TITLES.value.Accruals,
            routeName: ROUTE_NAMES.Accruals,
          },
        ],
      },
      {
        name: NAVIGATION_TITLES.value.TaxDeclaration,
        icon: "mdi-file-document-outline",
        children: [
          {
            name: NAVIGATION_TITLES.value.TaxDeclaration2033,
            routeName: ROUTE_NAMES.TaxDeclaration2033,
          },
          {
            name: NAVIGATION_TITLES.value.TaxDeclaration2031,
            routeName: ROUTE_NAMES.TaxDeclaration2031,
          },
        ],
      },
      {
        name: NAVIGATION_TITLES.value.Documents,
        icon: "mdi-folder-outline",
        routeName: ROUTE_NAMES.Documents,
      },
      {
        name: NAVIGATION_TITLES.value.PartnersArea,
        icon: "mdi-handshake-outline",
        routeName: ROUTE_NAMES.PartnersArea,
        new: true,
      },
      {
        name: NAVIGATION_TITLES.value.EventsYearEnd,
        isButton: true,
        routeName: ROUTE_NAMES.EventsYearEnd,
      },
    ];

    return navigationItems;
  });

  const taxRegimeItems: ComputedRef<NavigationItems> = computed(() => {
    let taxRegimeItems:
      | NavigationItem[]
      | (
          | (NavigationItem & {
              children?: (NavigationItemChildren | undefined)[];
            })
          | undefined
        )[] = [];

    switch (taxRegime.value) {
      case TaxRegime.IR_2072:
        taxRegimeItems = navigationItemsIR.value;
        break;
      case TaxRegime.IS_2065:
        taxRegimeItems = navigationItemsIS.value;
        break;
      case TaxRegime.LMNP_2031:
        taxRegimeItems = navigationItemsLMNP.value;
    }

    return (taxRegimeItems as NavigationItems)
      .filter((item) => item !== undefined)
      .map((item) => {
        if (item?.children) {
          item.children = item.children.filter(
            (children) => children !== undefined
          );
          return item;
        }
        return item;
      }) as NavigationItems;
  });

  return {
    NAVIGATION_TITLES,
    // Remove undefined items
    taxRegimeItems,
  };
};
