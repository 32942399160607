import {
  AnomalyCode,
  AnomalyError,
  AnomalyErrorResult,
  CheckNewAnomaliesParams,
  CheckNewAnomaliesType,
  JournalComposedEntryWithTransaction,
  JournalEntryLine,
  LedgerAccountEnum,
  TypeReference,
} from "..";
import { anomaliesHelpers } from "./anomaliesHelper.lib";

export type CheckAssetAnomaliesParams = Omit<
  CheckNewAnomaliesParams<CheckNewAnomaliesType.transaction>,
  "checkNewAnomaliesType" | "params"
> & {
  operation: JournalComposedEntryWithTransaction;
  line: JournalEntryLine;
};

export const checkAssetAnomalies = ({
  operation,
  line,
  data,
  options,
}: CheckAssetAnomaliesParams): AnomalyErrorResult[] => {
  const { getRealEstateAsset, isLMNP, getOptionAnomalyReference } = anomaliesHelpers({
    data,
    options,
  });
  const anomaliesErrorsLine: AnomalyErrorResult[] = [];

  /* Add anomaly if the line account not respect rules. */
  if (line.account === LedgerAccountEnum.N213000) {
    const realEstateAssetRef = line.refs?.find((ref) => ref.type === TypeReference.realEstateAsset);
    if (realEstateAssetRef) {
      const realEstateAsset = getRealEstateAsset(realEstateAssetRef.referredId);
      if (realEstateAsset && realEstateAsset.boughtPrice !== Math.abs(line.amount)) {
        const referenceId = getOptionAnomalyReference(AnomalyCode.asset);
        if (referenceId && referenceId !== realEstateAssetRef.referredId) {
          return anomaliesErrorsLine;
        }
        anomaliesErrorsLine.push({
          anomalyError: AnomalyError.realEstateAssetNotSameAcquisitionAmount,
          fromReferenceType: "transaction",
          fromReferenceId: operation.transactionId,
          toReferenceType: realEstateAssetRef.type,
          toReferenceId: realEstateAssetRef.referredId,
        });
      }
    }
  }

  if (line.account === LedgerAccountEnum.N201100) {
    const realEstateAssetRef = line.refs?.find((ref) => ref.type === TypeReference.realEstateAsset);
    if (realEstateAssetRef) {
      const realEstateAsset = getRealEstateAsset(realEstateAssetRef.referredId);
      if (isLMNP && realEstateAsset && realEstateAsset.boughtFee !== Math.abs(line.amount)) {
        const referenceId = getOptionAnomalyReference(AnomalyCode.asset);
        if (referenceId && referenceId !== realEstateAssetRef.referredId) {
          return anomaliesErrorsLine;
        }
        anomaliesErrorsLine.push({
          anomalyError: AnomalyError.realEstateAssetNotSameBoughtFeeAmount,
          fromReferenceType: "transaction",
          fromReferenceId: operation.transactionId,
          toReferenceType: realEstateAssetRef.type,
          toReferenceId: realEstateAssetRef.referredId,
        });
      }
    }
  }

  return anomaliesErrorsLine;
};
