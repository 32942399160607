











































































import { ROUTE_NAMES } from "@/router/routes";
import {
  accountingBalanceSheetsStore,
  accountingPeriodsStore,
  operationAccrualsStore,
} from "@/store";
import {
  AccountingPeriod,
  LedgerAccountEnum,
  OperationAccrualLib,
  OperationAccrualsModel,
} from "@edmp/api";
import {
  computed,
  defineComponent,
  PropType,
  Ref,
  ref,
  watchEffect,
} from "@vue/composition-api";
import Decimal from "decimal.js-light";
import AccrualCards from "../AccrualCards.vue";
import AccrualForm from "./AccrualForm.vue";

export default defineComponent({
  name: "AccrualMassCreate",
  props: {
    viewType: {
      type: String as PropType<"recovery" | "closure">,
      default: "closure",
    },
    account: {
      type: String as PropType<LedgerAccountEnum>,
      required: true,
    },
    accountingPeriod: {
      type: Object as PropType<AccountingPeriod>,
      required: false,
    },
    initialRecoveryAmount: {
      type: Decimal,
      required: false,
    },
  },
  components: {
    AccrualCards,
    AccrualForm,
  },
  setup(props, context) {
    const operationAccruals = computed<
      OperationAccrualsModel.OperationAccrual[]
    >(() => {
      const filterByAccount = (
        operationAccrual: OperationAccrualsModel.OperationAccrual
      ) =>
        operationAccrual.journalEntry.lines?.some(
          (line) =>
            OperationAccrualLib.getDoubleEntryAccount(line.account) ===
            props.account
        );

      if (props.viewType === "recovery") {
        const accountingPeriodId =
          props.accountingPeriod?.id ?? accountingPeriodsStore.currentId;
        return operationAccrualsStore
          .recoveryOperationAccrualsByAccountingPeriodId(accountingPeriodId)
          .filter(filterByAccount);
      } else if (props.viewType === "closure") {
        return operationAccrualsStore.operationAccruals.filter(
          (operationAccrual) =>
            filterByAccount(operationAccrual) &&
            !operationAccrual.reconciliation?.date &&
            !operationAccrual.reportedLoss
        );
      } else {
        return operationAccrualsStore.operationAccruals.filter(
          (operationAccrual) =>
            !OperationAccrualLib.isOperationRecovery(operationAccrual) &&
            !OperationAccrualLib.isOperationClosure(operationAccrual) &&
            filterByAccount(operationAccrual)
        );
      }
    });
    const accrualType = computed<
      | OperationAccrualsModel.Tags.RECOVERY
      | OperationAccrualsModel.Tags.CLOSURE
      | undefined
    >(() => {
      let result:
        | OperationAccrualsModel.Tags.RECOVERY
        | OperationAccrualsModel.Tags.CLOSURE
        | undefined = undefined;
      switch (props.viewType) {
        case "recovery":
          result = OperationAccrualsModel.Tags.RECOVERY;
          break;
        case "closure":
          result = OperationAccrualsModel.Tags.CLOSURE;
          break;
      }
      return result;
    });

    const previousBalanceSheetAmount = computed(
      () =>
        accountingBalanceSheetsStore.getPreviousYearAccountingBalanceSheet?.lines.find(
          (line) => line.account === props.account
        )?.amount ?? 0
    );

    const recoveryOperationAccruals = computed(() =>
      operationAccrualsStore.recoveryOperationAccrualsByAccountingPeriodId(
        props.accountingPeriod?.id ?? accountingPeriodsStore.currentId
      )
    );
    const previousTotalAmount = computed(
      () =>
        new Decimal(
          recoveryOperationAccruals.value
            .filter((recoveryOperationAccrual) =>
              recoveryOperationAccrual.journalEntry.lines?.find(
                (line) =>
                  OperationAccrualLib.getDoubleEntryAccount(line.account) ===
                  props.account
              )
            )
            .reduce((acc, operationAccrual) => {
              const totalAmount =
                OperationAccrualLib.getAmount(operationAccrual);
              return acc + totalAmount;
            }, 0)
        )
    );
    const remainingRecoveryAmount = computed(() => {
      return new Decimal(previousTotalAmount.value)
        .abs()
        .minus(new Decimal(props.initialRecoveryAmount ?? 0).abs())
        .abs()
        .toNumber();
    });

    const getRemovable = () => {
      if (props.viewType) {
        return props.viewType;
      } else {
        return false;
      }
    };

    const openCreate: Ref<boolean> = ref(false);
    watchEffect(() => {
      openCreate.value = !operationAccruals.value.length;
    });
    const cancel = async () => {
      if (openCreate.value) {
        openCreate.value = false;
        return;
      }
      context.emit("closed");
    };

    return {
      openCreate,
      operationAccruals,
      accrualType,
      getRemovable,
      cancel,
      ROUTE_NAMES,
      previousBalanceSheetAmount,
      remainingRecoveryAmount,
    };
  },
});
